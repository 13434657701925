import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"

import styled from "styled-components"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <ArticleContentContainer>
        <Layout location={this.props.location} title={siteTitle}>
          <SEO
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            img={
              "https://www.nucleotype.com" +
              post.frontmatter.thumbnail.childImageSharp.fluid.src
            }
            url={"https://www.nucleotype.com" + post.fields.slug}
          />

          <header>
            <H1>{post.frontmatter.title}</H1>
            <Date>{post.frontmatter.date}</Date>
          </header>
          <MDXRenderer>{post.body}</MDXRenderer>
          <HRLine />
          <footer>
            <Bio />
          </footer>
        </Layout>
      </ArticleContentContainer>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(height: 700) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const H1 = styled.h1`
  font-size: 44px;
  margin-top: 1.75rem;
  margin-bottom: 0px;
  line-height: 100%;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`
const Date = styled.p`
  font-size: 16px;
  line-height: 1.75rem;
  display: block;
  margin-bottom: 1.75rem;
`

const ArticleContentContainer = styled.article`
  margin: auto;
  h2 {
    margin-top: 65px;
    font-size: 34px;
  }
  @media (max-width: 1500px) {
    max-width: 600px;
  }
  @media (max-width: 1250px) {
    margin-left: 275px;
    max-width: 575px;
  }
  @media (max-width: 1160px) {
    margin-left: 225px;
  }
  @media (max-width: 1100px) {
    margin-left: 100px;
  }
  @media (max-width: 1000px) {
    margin: auto;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 28px;
    }
  }
`
const HRLine = styled.hr`
  margin-bottom: 10px;
  margin-top: 50px;
`
